import React, { Component } from "react";

import "./css/header.scss";

export default class Header extends Component {
    render() {
        return (
            <header id="header">
                <div id="header-desktop">
                    <div className="logo"></div>
                    <ul className="nav">
                        <li>
                            <a href="https://victoriavr.com/" target="_blank" rel="noreferrer">
                                Victoria VR website
                            </a>
                        </li>
                        <li>
                            <a href="https://stake.victoriavr.com/" target="_blank" rel="noreferrer">
                                Staking
                            </a>
                        </li>
                        {!this.props.network &&
                            <li>
                                <button className="connect button" onClick={async (e) => this.props.connect()}>
                                    <span> CONNECT METAMASK </span>
                                </button>
                            </li>
                        }
                    </ul>
                </div>
                <div id="header-mobile">
                    <ul className={"top" + (this.props.network ? " connected" : "")}>
                        <div className="logo"></div>
                        {!this.props.network &&
                            <React.Fragment>
                                <li>
                                    <button className="connect button" onClick={async (e) => this.props.connect()}>
                                        <span> CONNECT METAMASK </span>
                                    </button>
                                </li>
                            </React.Fragment>
                        }
                    </ul>
                    <ul className="bottom">
                        <li>
                            <a href="https://victoriavr.com/" target="_blank" rel="noreferrer">
                                Victoria VR website
                            </a>
                        </li>
                        <li>
                            <a href="https://stake.victoriavr.com/" target="_blank" rel="noreferrer">
                                Staking
                            </a>
                        </li>
                    </ul>
                </div>
            </header>
        );
    }
}

