import React, { Component } from "react";

import { ethers } from "ethers";
import abi from "./abi/mint.json";
import config from "./config.json";

import "./css/center.scss";

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export default class Center extends Component {
    state = {
        address: "",
        tiers: [0, 0, 0, 0],
        processing: false,
        claimed: false,
        nothingToClaim: false,
        afterStartTime: false
    }

    constructor(props) {
        super(props);
        this.click = this.click.bind(this);
        this.change = this.change.bind(this);
    }

    async componentDidMount() {
        await this.updateClaimable();
        
        const provider = new ethers.providers.InfuraProvider(config.chainId, config.infuraKey);
        const contract = new ethers.Contract(config.contractAddress, abi, provider);
        const startTime = await contract.startTime();

        if (Number(startTime) < Math.round(Date.now() / 1000)) {
            this.setState({ afterStartTime: true });
        }
    }

    async updateClaimable() {
        if (!ethers.utils.isAddress(this.state.address)) {
            const tiers = [0, 0, 0, 0];
            this.setState({ tiers });
            return;
        }
        const tiers = [0, 0, 0, 0];

        const provider = new ethers.providers.InfuraProvider(config.chainId, config.infuraKey);
        const contract = new ethers.Contract(config.contractAddress, abi, provider);
        const whitelist = await contract.getUserWhitelist(this.state.address);
        for (const tier of whitelist) {
            tiers[tier - 1]++;
        }

        let nothingToClaim = false;
        if (tiers.filter(count => count === 0).length === 4) nothingToClaim = true;

        this.setState({ tiers, nothingToClaim, claimed: false });
    }

    change(e) {
        this.setState({ address: e.target.value.trim(), nothingToClaim: false }, () => this.updateClaimable());
    }

    async click() {
        if (this.state.processing) return;
        this.setState({ processing: true });

        if (!this.props.signer) {
            await this.props.connect();
            return;
        }

        try {
            const contract = new ethers.Contract(config.contractAddress, abi, this.props.signer);
            const tx = await contract.mintAll(this.state.address);
            //await sleep(5000);

            await tx.wait();
            this.setState({ tiers: [0, 0, 0, 0], claimed: true });
        } catch (e) {
            if (!e.message.includes("denied transaction")) {
                alert("Something went wrong");
            }   
        }
        
        this.setState({ processing: false });
    }

    render() {
        let list;

        if (this.state.nothingToClaim) {
            list = <li> 0 VR Lands</li>
        } else {
            list = this.state.tiers.map((count, tier) => count > 0 ? <li key={tier}>
                <span className="highlight"> {count} </span>× tier {tier + 1}
            </li> : "");
        }
        
        const showList = this.state.tiers.filter(count => count === 0).length !== 4 || this.state.nothingToClaim;
        const buttonDisabled = this.state.tiers.filter(count => count === 0).length === 4 || !this.state.afterStartTime;

        return (
            <React.Fragment>
                <div id="center">
                    <div id="center-top">
                        <div id="claimable">
                            <h2> Victoria VR Lands claim </h2>
                            <div> Enter your wallet address: </div>
                            <input id="wallet-input" type="text" value={this.state.address} onChange={this.change} readOnly={this.state.processing}/>
                            {(showList) &&
                                <React.Fragment>
                                    <div> This wallet can claim: </div>
                                    <ul> {list} </ul>
                                </React.Fragment>
                            }
                            {this.state.claimed && <div id="claim-result"> SUCCESSFULLY CLAIMED! </div>}
                        </div>
                    </div>
                    <div id="center-bottom">
                        <button className={"button" + (!buttonDisabled ? "" : " disabled")} disabled={buttonDisabled} onClick={this.click}>
                            {this.state.processing ? 
                            <div className="loader"></div> 
                                :
                                <span> claim your vr lands </span>}
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

