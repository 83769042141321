import React, { Component } from "react";
import { ethers } from "ethers";
import config from "./config.json";

import Header from "./Header";
import Center from "./Center";

import "./css/global.scss";
import "./css/button.scss";

class App extends Component {
    state = {
        provider: null,
        signer: null,
        network: null,
        loading: false
    }

    constructor(props) {
        super(props);
        this.connect = this.connect.bind(this);

    }

    async componentDidMount()
    {
        await this.connect();
    }

    async connect() 
    {
        try {
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            const provider = await new ethers.providers.Web3Provider(window.ethereum, "any");
            const network = (await provider.getNetwork()).chainId;

            let signer;
            if (network === config.chainId) {
                signer = await provider.getSigner();
            } else {
                window.ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [{
                        chainId: config.chainId === 42 ? "0x2A" : "0x1"
                    }]
                });
            }

            this.setState({ provider, signer, network });
        } catch (e) {
            console.log(e);
            this.setState({ provider: null, signer: null, network: null });
        }

        window.ethereum.on("accountsChanged", async accounts => {
            await this.connect();
           //window.location.reload();
        });

        window.ethereum.on("chainChanged", async network => {
            await this.connect();
        });
    }

    render() 
    {
        return (
            <React.Fragment>
                <Header connect={this.connect} network={this.state.network}/>
                <Center connect={this.connect} signer={this.state.signer}></Center>
            </React.Fragment>
        );
    }
}

export default App;